import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { themeV2 } from 'src/theme/mysherpas-theme-option';

const Background = styled(Box)({
  width: '100%',
  backgroundColor: themeV2.colors.blue[800],
  padding: '52px 0',
  textAlign: 'center',
  marginTop: 95,
});

const TopText = styled(Typography)({
  color: themeV2.neutral.white.default,
  fontWeight: 400,
  fontSize: '14px',
  marginBottom: '24px',
  lineHeight: 'normal',
});

const LocationText = styled(Typography)({
  color: themeV2.neutral.white.default,
  fontWeight: 400,
  fontSize: '18px',
  marginTop: '24px',
  lineHeight: 'normal',
});

const CopyrightText = styled(Typography)({
  color: themeV2.neutral.white.default,
  fontWeight: 400,
  fontSize: '12px',
  marginTop: '80px',
  lineHeight: 'normal',
});

const LocationBox = styled(Box)({
  display: 'inline-flex',
  gap: '24px',
});

export const FooterStyles = {
  Background,
  CopyrightText,
  LocationBox,
  LocationText,
  TopText,
};
