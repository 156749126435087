import * as React from 'react';

const CommunicationIcon = () => (
  <svg
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="69" height="69" rx="34.5" fill="#F8F8FA" />
    <path
      d="M21 54H51C52.65 54 54 52.65 54 51V28.5C54 26.85 52.65 25.5 51 25.5H18C16.35 25.5 15 26.85 15 28.5V50.4"
      stroke="#101B4B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.25 31.6484L34.5 38.8484L21.75 31.6484"
      stroke="#101B4B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 19.5H48"
      stroke="#FAC014"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.5 13.5H43.5"
      stroke="#FAC014"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CommunicationIcon;
