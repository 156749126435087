import * as React from 'react';

const DeliveryNetworkIcon = () => (
  <svg
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="69" height="69" rx="34.5" fill="#F8F8FA" />
    <path
      d="M49.5 45V40.5H19.5V45"
      stroke="#101B4B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.5 37.5V45"
      stroke="#101B4B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.5 54C35.7426 54 36.75 52.9926 36.75 51.75C36.75 50.5074 35.7426 49.5 34.5 49.5C33.2574 49.5 32.25 50.5074 32.25 51.75C32.25 52.9926 33.2574 54 34.5 54Z"
      fill="#101B4B"
    />
    <path
      d="M49.5 54C50.7426 54 51.75 52.9926 51.75 51.75C51.75 50.5074 50.7426 49.5 49.5 49.5C48.2574 49.5 47.25 50.5074 47.25 51.75C47.25 52.9926 48.2574 54 49.5 54Z"
      fill="#101B4B"
    />
    <path
      d="M19.5 54C20.7426 54 21.75 52.9926 21.75 51.75C21.75 50.5074 20.7426 49.5 19.5 49.5C18.2574 49.5 17.25 50.5074 17.25 51.75C17.25 52.9926 18.2574 54 19.5 54Z"
      fill="#101B4B"
    />
    <path
      d="M42 31.5H27C26.175 31.5 25.5 30.825 25.5 30V18C25.5 17.175 26.175 16.5 27 16.5H42C42.825 16.5 43.5 17.175 43.5 18V30C43.5 30.825 42.825 31.5 42 31.5Z"
      stroke="#FAC014"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33 21H36"
      stroke="#FAC014"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DeliveryNetworkIcon;
