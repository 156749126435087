import * as React from 'react';
import ChecklistIcon from 'src/icons/checklist-icon';
import CommunicationIcon from 'src/icons/communication-icon';
import DeliveryNetworkIcon from 'src/icons/delivery-network-icon';
import DocumentFileIcon from 'src/icons/document-file-icon';
import GroupPeopleIcon from 'src/icons/group-people-icon';
import ShieldIcon from 'src/icons/shield-icon';

export const ITEMS = [
  {
    title: 'Automated and Simplified Tasks',
    subtitle:
      'Automate the information collection process and eliminate manual data entry.',
    icon: <ChecklistIcon />,
  },
  {
    title: 'One Communication Channel',
    subtitle:
      'Centralised hub for borrowers, lenders, and other relevant parties get real time updates on the loan',
    icon: <CommunicationIcon />,
  },
  {
    title: 'Unified Document Management',
    subtitle:
      'Store and manage all your documents in one place and eliminate back and forth.',
    icon: <DocumentFileIcon />,
  },
  {
    title: 'Real-Time Collaboration',
    subtitle:
      'Advanced team management and collaboration to improve speed and efficency in the loan journey.',
    icon: <GroupPeopleIcon />,
  },
  {
    title: 'Adapts to your process',
    subtitle:
      'Tailored loan experience to meet your unique needs and protocols.',
    icon: <DeliveryNetworkIcon />,
  },
  {
    title: 'Security and Compliance',
    subtitle:
      'Our platform adheres to data protection standards, ensuring your personal and financial information remains safe.',
    icon: <ShieldIcon />,
  },
];
