import type { AlertProps } from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import type { LinkProps } from '@mui/material/Link';
import Link from '@mui/material/Link';
import type { StackProps } from '@mui/material/Stack';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import type { TabProps } from '@mui/material/Tab';
import MaterialTab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { themeV2 } from 'src/theme/mysherpas-theme-option';

type LandingTabProps = TabProps & {
  selected: boolean;
};

const TopAlert = styled((props: AlertProps) => <Alert {...props} />)({
  backgroundColor: themeV2.colors.red[10],
  justifyContent: 'center',
});

const TopAlertTitle = styled(AlertTitle)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '18px',
  },
  fontWeight: 'normal',
  color: themeV2.colors.red[50],
}));

const Header = styled((props: StackProps) => <Stack {...props} />)(
  ({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('xs')]: {
      padding: '72px 26px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '135px 36px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '100px 140px',
    },
    b: `1px solid ${themeV2.neutral.grey[100]}`,
    backgroundColor: themeV2.colors.blue[800],
  })
);

const HeaderLeft = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '828px',
});

const Title = styled('h1')(({ theme }) => ({
  color: themeV2.neutral.white.default,
  fontWeight: 500,
  fontSize: '64px',
  [theme.breakpoints.up('xs')]: {
    fontSize: '32px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '56px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '64px',
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  marginTop: '16px',
  color: themeV2.neutral.white.default,
  [theme.breakpoints.up('xs')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '20px',
  },
}));

const HeaderLink = styled((props: LinkProps) => <Link {...props} />)(
  ({ theme }) => ({
    color: themeV2.colors.yellow[100],
    marginTop: '16px',
    textDecoration: 'underline',
    [theme.breakpoints.up('xs')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
  })
);

const HeaderSignin = styled(Box)({
  backgroundColor: themeV2.neutral.white.default,
  borderRadius: '8px',
  padding: '12px',
  paddingBottom: '-16px',
});

const Tab = styled((props: LandingTabProps) => <MaterialTab {...props} />)(
  ({ selected }) => ({
    borderRadius: '8px',
    fontSize: '18px',
    color: selected ? themeV2.colors.blue[100] : themeV2.neutral.grey[100],
    backgroundColor: selected
      ? themeV2.colors.blue[10]
      : themeV2.neutral.white.default,
    border: `1px solid ${selected ? themeV2.colors.blue[100] : themeV2.neutral.grey[80]
      }`,
    width: 'calc(50% - 6px)',
    '&:last-child': {
      marginLeft: '10px',
    },
  })
);

const HiddenTab = styled((props: LandingTabProps) => <MaterialTab {...props} />)({
  height: '1px',
  width: '1px',
});

const FeaturesSection = styled('section')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    padding: '40px 15px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '64px 36px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '135px 120px',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const FeaturesTitle = styled('h2')(({ theme }) => ({
  color: themeV2.colors.blue[800],
  fontWeight: 500,
  textAlign: 'center',
  [theme.breakpoints.up('xs')]: {
    fontSize: '28px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '40px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '48px',
  },
}));

const FeaturesSubtitle = styled(Typography)(({ theme }) => ({
  color: themeV2.neutral.grey[90],
  maxWidth: '741px',
  marginTop: '16px',
  textAlign: 'center',
  [theme.breakpoints.up('xs')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
}));

const List = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  maxWidth: '1330px',
  marginTop: 'calc(64px - 105px)',
  padding: 0,
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
  },
}));

const ListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '400px',
  marginTop: '105px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '64px',
  },
}));

const ItemTitle = styled(Typography)(({ theme }) => ({
  marginTop: 20,
  color: themeV2.colors.blue[800],
  fontWeight: 500,
  [theme.breakpoints.up('xs')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
  },
}));

const ItemSubtitle = styled(Typography)(({ theme }) => ({
  marginTop: 12,
  color: themeV2.colors.blue[800],
  [theme.breakpoints.up('xs')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
}));

const CTASection = styled('section')(({ theme }) => ({
  width: '95%',
  maxWidth: '1458px',
  backgroundColor: themeV2.neutral.grey[10],
  [theme.breakpoints.up('xs')]: {
    padding: '32px 0',
    borderRadius: '20px',
  },
  [theme.breakpoints.up('sm')]: {
    padding: '56px 0',
  },
  [theme.breakpoints.up('md')]: {
    padding: '84px 0',
    borderRadius: '50px',
  },
  margin: 'auto',
  textAlign: 'center',
}));

const CTAHeading = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 500,
  color: themeV2.colors.blue[800],
  [theme.breakpoints.up('xs')]: {
    fontSize: '24px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '40px',
  },
  padding: '13px',
}));

const CTASubtitle = styled(Typography)(({ theme }) => ({
  marginTop: '16px',
  textAlign: 'center',
  fontWeight: 500,
  color: themeV2.neutral.grey[90],
  [theme.breakpoints.up('xs')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
  },
}));

const CTAButton = styled((props: ButtonProps) => <Button {...props} />)({
  marginTop: '48px',
  width: '280px',
});

export const LandingPageStyles = {
  CTAButton,
  CTAHeading,
  CTASection,
  CTASubtitle,
  FeaturesSection,
  FeaturesSubtitle,
  FeaturesTitle,
  Header,
  HeaderLeft,
  HeaderLink,
  HeaderSignin,
  HiddenTab,
  ItemSubtitle,
  ItemTitle,
  List,
  ListItem,
  Subtitle,
  Tab,
  Title,
  TopAlert,
  TopAlertTitle,
};
