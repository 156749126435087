import * as React from 'react';

export const ChecklistIcon = () => (
    <svg
        width='69'
        height='69'
        viewBox='0 0 69 69'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect width='69' height='69' rx='34.5' fill='#F8F8FA' />
        <path
            d='M32.3477 54.75H20.3477C18.6977 54.75 17.3477 53.4 17.3477 51.75V23.25C17.3477 21.6 18.6977 20.25 20.3477 20.25H44.3477C45.9977 20.25 47.3477 21.6 47.3477 23.25V39.75'
            stroke='#101B4B'
            strokeWidth='2'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M32.3477 20.25C34.0045 20.25 35.3477 18.9069 35.3477 17.25C35.3477 15.5931 34.0045 14.25 32.3477 14.25C30.6908 14.25 29.3477 15.5931 29.3477 17.25C29.3477 18.9069 30.6908 20.25 32.3477 20.25Z'
            stroke='#101B4B'
            strokeWidth='2'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M30.8477 29.25H39.8477'
            stroke='#101B4B'
            strokeWidth='2'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M30.8477 36.75H39.8477'
            stroke='#101B4B'
            strokeWidth='2'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M24.8477 30.75C25.6761 30.75 26.3477 30.0784 26.3477 29.25C26.3477 28.4216 25.6761 27.75 24.8477 27.75C24.0192 27.75 23.3477 28.4216 23.3477 29.25C23.3477 30.0784 24.0192 30.75 24.8477 30.75Z'
            fill='#101B4B'
        />
        <path
            d='M24.8477 38.25C25.6761 38.25 26.3477 37.5784 26.3477 36.75C26.3477 35.9216 25.6761 35.25 24.8477 35.25C24.0192 35.25 23.3477 35.9216 23.3477 36.75C23.3477 37.5784 24.0192 38.25 24.8477 38.25Z'
            fill='#101B4B'
        />
        <path
            d='M38.2734 49.1562L43.4634 54.3462L51.6534 46.1562'
            stroke='#FAC014'
            strokeWidth='2'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        </svg>
);

export default ChecklistIcon;
