import * as React from 'react';

const ShieldIcon = () => (
  <svg
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="69" height="69" rx="34.5" fill="#F8F8FA" />
    <path
      d="M38.8504 40.4984H30.3004C29.4004 40.4984 28.6504 39.7484 28.6504 38.8484V31.7984C28.6504 30.8984 29.4004 30.1484 30.3004 30.1484H38.8504C39.7504 30.1484 40.5004 30.8984 40.5004 31.7984V38.8484C40.5004 39.7484 39.7504 40.4984 38.8504 40.4984Z"
      stroke="#FAC014"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.5 29.4008V27.7508C31.5 26.8508 32.1 25.6508 32.85 25.3508C35.25 24.0008 37.5 25.6508 37.5 27.9008V29.5508"
      stroke="#FAC014"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.5 36.75C35.3284 36.75 36 36.0784 36 35.25C36 34.4216 35.3284 33.75 34.5 33.75C33.6716 33.75 33 34.4216 33 35.25C33 36.0784 33.6716 36.75 34.5 36.75Z"
      fill="#FAC014"
    />
    <path
      d="M19.5 16.5H49.5V36.9C49.5 42.75 46.5 47.85 41.7 50.55L34.5 54L27.45 50.55C22.5 47.7 19.5 42.75 19.5 36.9V22.5"
      stroke="#101B4B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShieldIcon;
