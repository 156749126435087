import Box from '@mui/material/Box';
import Image from 'next/image';
import { FC } from 'react';

import { FooterStyles } from './footer.styles';

const Footer: FC = () => (
  <FooterStyles.Background>
    <Box>
      <FooterStyles.TopText>Powered by</FooterStyles.TopText>
      <Image
        src="/static/250px_DarkMode.png"
        alt="mysherpas"
        width="250"
        height="50"
        layout='fixed'
      />
      <FooterStyles.LocationText>
        257 E 200 S STE 525, Salt Lake City, UT 84111
      </FooterStyles.LocationText>
      <FooterStyles.LocationBox>
        <FooterStyles.LocationText>info@mysherpas.io</FooterStyles.LocationText>
        <FooterStyles.LocationText>385 799 6595</FooterStyles.LocationText>
      </FooterStyles.LocationBox>
    </Box>
    <Box>
      <FooterStyles.CopyrightText>
        Copyright {new Date().getFullYear()}. mysherpas - All rights reserved.
      </FooterStyles.CopyrightText>
    </Box>
  </FooterStyles.Background>
);

export default Footer;
