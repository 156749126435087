import * as React from 'react';

const GroupPeopleIcon = () => (
  <svg
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="69" height="69" rx="34.5" fill="#F8F8FA" />
    <path
      d="M20.25 34.5C22.3211 34.5 24 32.8211 24 30.75C24 28.6789 22.3211 27 20.25 27C18.1789 27 16.5 28.6789 16.5 30.75C16.5 32.8211 18.1789 34.5 20.25 34.5Z"
      stroke="#FAC014"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 39H17.55C16.05 39 14.85 40.05 14.55 41.4L13.5 45.9"
      stroke="#FAC014"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.75 34.5C50.8211 34.5 52.5 32.8211 52.5 30.75C52.5 28.6789 50.8211 27 48.75 27C46.6789 27 45 28.6789 45 30.75C45 32.8211 46.6789 34.5 48.75 34.5Z"
      stroke="#FAC014"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45 39H51.45C52.95 39 54.15 40.05 54.45 41.4L55.5 45.9"
      stroke="#FAC014"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.5 33C36.9853 33 39 30.9853 39 28.5C39 26.0147 36.9853 24 34.5 24C32.0147 24 30 26.0147 30 28.5C30 30.9853 32.0147 33 34.5 33Z"
      stroke="#101B4B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.6 47.55L41.4 42C40.95 40.2 39.45 39 37.65 39H31.2C29.4 39 27.9 40.2 27.45 42L26.25 47.55"
      stroke="#101B4B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GroupPeopleIcon;
