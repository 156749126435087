import * as React from 'react';

const DocumentFileIcon = () => (
  <svg
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="69" height="69" rx="34.5" fill="#F8F8FA" />
    <path
      d="M15 51V27C15 25.35 16.35 24 18 24H29.7L35.25 30H51C52.65 30 54 31.35 54 33V51C54 52.65 52.65 54 51 54H21"
      stroke="#101B4B"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 19.5V16.5C21 15.6 21.6 15 22.5 15H46.5C47.4 15 48 15.6 48 16.5V25.5"
      stroke="#FAC014"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocumentFileIcon;
